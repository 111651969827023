import axios from "axios";
import { uriDivert } from "../../services/api/APIs";

export const hr_uris_GET = async (flag, param, pilotFlag) => {
  const company = window.location.pathname.split("/")[2];
  const URI = uriDivert(pilotFlag);
  let auth = sessionStorage.getItem(company);

  if (auth === null) auth = await localStorage.getItem(company);
  const headerValue = {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  };

  switch (flag) {
    case "totalQna":
      return await axios.get(`${URI}/user/qnaList`, {
        ...headerValue,
        ...{
          params: {
            ...param.queryKey[1],
          },
        },
      });

    case "getRemovedItem":
      return axios.get(`${URI}/user/qnaList/tempDelete`, {
        ...headerValue,
      });

    // 그룹채용 리스트를 가져오는 API 요청
    case "totalGroup":
      const page = param.queryKey[1].pageNo;
      return await axios.get(`${URI}/users/group-manage/groups`, {
        ...headerValue,
        params: { pageNo: page, ...param.queryKey[1] },
      });

    // 공고의 응시자 리스트를 가져오는 API 요청
    case "testerList":
      return axios.get(
        `${URI}/users/group-manage/groups/${param.queryKey[1].groupIdx}/testers`,
        {
          ...headerValue,
          params: {
            perPage: param.queryKey[1].perPage,
            pageNo: param.queryKey[1].pageNo,
            ...param.queryKey[1]?.searchInfo,
          },
        }
      );

    // 공고의 화상룸 리스트를 가져오는 API 요청
    case "roomList":
      return axios.get(
        `${URI}/user/groupManage/${param.queryKey[1]?.index}/roomList`,
        {
          ...headerValue,
          ...{
            params: {
              perPage: param.queryKey[1].perPage,
            },
          },
        }
      );

    // 화상룸의 응시자 리스트를 가져오는 API 요청
    case "roomTesterList":
      return axios.get(
        `${URI}/user/groupManage/roomList/${param.queryKey[1]?.index}/testerList`,
        {
          ...headerValue,
          ...{
            params: {
              pageNo: param.queryKey[1]?.pageNo,
              perPage: param.queryKey[1]?.perPage,
              ...param.queryKey[1]?.searchInfo,
            },
          },
        }
      );

    // 공고의 화상룸의 응시자들 전화 요청 상태를 가져오는 API 요청
    case "getCallStatus":
      return axios.get(
        `${URI}/user/groupManage/roomList/${param.queryKey[1]?.index}/call`,
        {
          ...headerValue,
        }
      );

    // [HR담당자] 응시자 리스트 등록 템플릿 다운로드 API

    case "testerTemplate_download":
      return axios.get(
        `${URI}/users/tester-register/templates/groups/${param.queryKey[1].groupIdx}`,
        {
          ...headerValue,
        }
      );

    // 화상룸 감독관 리스트 템플릿 파일을 다운로드하는 API
    case "managerTemplate_download":
      return axios.get(
        `${URI}/download/groupManage/${param.queryKey[1]?.groupIdx}/managerTemplate`,
        {
          ...headerValue,
        }
      );

    // [HR담당자] 레이아웃 조회 API
    case "userLayout":
      return axios.get(`${URI}/users/layout`, {
        ...headerValue,
        ...{
          params: {
            companyCode: param.queryKey[1].companyCode,
          },
        },
      });
    // [HR담당자] 현재 HR담당자 리스트 조회 API
    case "hrList":
      return axios.get(`${URI}/users/approved`, {
        ...headerValue,
        ...{
          params: {
            pageNo: param.queryKey[1]?.pageNo,
          },
        },
      });

    // // [HR담당자] 가입 대기자 조회 API
    // case "userWaiting":
    //   return axios.get(`${URI}/user/waiting`, {
    //     ...headerValue,
    //   });

    // 공고에 등록할 회사의 규준 리스트를 가져오는 API 요청
    case "normList":
      return axios.get(
        `${URI}/users/group-register/${param.queryKey[1].companyIdx}/norms`,
        {
          ...headerValue,
          params: {
            companyIdx: param.queryKey[1].companyIdx,
          },
        }
      );

    // 공고의 응시자 검사 상태 정보를 가져오는 API 요청
    case "testerStatus":
      return axios.get(
        `${URI}/user/groupManage/testerList/examInfo/${param.queryKey[1].testerIdx}`,
        {
          ...headerValue,
        }
      );

    // 공고의 특정 응시자들이 이동 가능한 화상룸 리스트를 확인하는 API
    case "moveRoomList":
      const data = param.queryKey[1].testerIdxs;
      const roomIdx = param.queryKey[1].roomIdx;
      return axios.get(
        `${URI}/user/groupManage/${param.queryKey[1].groupIdx}/moveRoom/${roomIdx}`,
        {
          ...headerValue,
          ...{
            params: { testerIdxs: data },
          },
        }
      );

    // 공고의 특정 응시자의 로그 정보를 확인하는 API
    // https://acgprojtest.insahr.co.kr/api#/HR%EB%8B%B4%EB%8B%B9%EC%9E%90%20API/GroupManageController_getTesterLog
    case "getTesterLogData":
      // index = param.queryKey[1].testerIdx;
      // const type = param.queryKey[1].type;
      // pageInfo = param.queryKey[1].page;
      return axios.get(`${URI}/user/groupManage/testerLog/${param.testerIdx}`, {
        ...headerValue,
        ...{
          params: {
            type: param.type,
            pageNo: param.pageNo,
          },
        },
      });
    // 공고의 특정 응시자의 로그 정보를 확인하는 API
    // https://acgprojtest.insahr.co.kr/api#/HR%EB%8B%B4%EB%8B%B9%EC%9E%90%20API/GroupManageController_getTesterLog
    case "getTesterLoginLogData":
      const type = param.queryKey[1].type;
      return axios.get(
        `${URI}/user/groupManage/testerLog/${param.queryKey[1].testerIdx}`,
        {
          ...headerValue,
          ...{
            params: {
              type: type,
              pageNo: param.queryKey[1].page,
            },
          },
        }
      );

    // 공고의 특정 응시자의 이미지 정보를 확인하는 API
    // https://acgprojtest.insahr.co.kr/api#/HR%EB%8B%B4%EB%8B%B9%EC%9E%90%20API/GroupManageController_getTesterImage
    case "getTesterImageData":
      return axios.get(
        `${URI}/user/groupManage/testerImage/${param.queryKey[1].testerIdx}`,
        {
          ...headerValue,
        }
      );
    // 공고의 특정 응시자의 이미지 정보를 확인하는 API
    // https://acgprojtest.insahr.co.kr/api#/HR%EB%8B%B4%EB%8B%B9%EC%9E%90%20API/GroupManageController_getTesterVideo
    case "getTesterVideoData":
      let submitValue = {};
      submitValue.recordType = param.queryKey[1].recordType;
      submitValue.pageNo = param.queryKey[1].page.pageNo;
      if (param.queryKey[1].recordType === "ALL") delete submitValue.recordType;
      return axios.get(
        `${URI}/user/groupManage/testerVideo/${param.queryKey[1].testerIdx}`,
        {
          ...headerValue,
          ...{
            params: submitValue,
          },
        }
      );

    // 그룹채용 공고를 상세 조회하는 API 요청
    // https://acgprojtest.insahr.co.kr/api#/HR%EB%8B%B4%EB%8B%B9%EC%9E%90%20API/GroupManageController_getDetailGroup
    case "getGroupDetail":
      return axios.get(
        `${URI}/users/group-manage/groups/${param.queryKey[1].groupIdx}`,
        {
          ...headerValue,
        }
      );

    // 공고의 특정 응시자를 검색하는 API
    case "getTesters":
      return axios.get(`${URI}/users/group-manage/testers/search`, {
        ...headerValue,
        ...{
          params: param.queryKey[1],
        },
      });

    // 모든 개인 레포트 결과지 다운로드
    case "reportDownloadPersonal":
      return axios.get(`${URI}/modify/${param.queryKey[1].groupIdx}/personal`, {
        ...headerValue,
        ...{
          params: param.queryKey[1],
        },
      });
    // 모든 개인 특화 요인 결과지 다운로드

    case "reportDownloadCustomized":
      return axios.get(
        `${URI}/modify/${param.queryKey[1].groupIdx}/cumstomized`,
        {
          ...headerValue,
          ...{
            params: param.queryKey[1],
          },
        }
      );
    // 모든 면접 레포트 결과지 다운로드
    case "reportDownloadInterviewer":
      return axios.get(
        `${URI}/modify/${param.queryKey[1].groupIdx}/interview`,
        {
          ...headerValue,
          ...{
            params: param.queryKey[1],
          },
        }
      );

    // 모든 HR 레포트 결과지 다운로드
    case "reportDownloadGroup":
      return axios.get(`${URI}/modify/${param.queryKey[1].groupIdx}/hr`, {
        ...headerValue,
        ...{
          params: param.queryKey[1],
        },
      });

    // [HR담당자] HR담당자 로그인 상태에서 핸드폰 번호 인증 API
    case "getVerifyNumber":
      return axios.get(`${URI}/user/auth/cell`, {
        ...headerValue,
        ...{
          params: param.queryKey[1],
        },
      });

    // 공고에 등록할 고객사 리스트를 가져오는 API 요청
    case "getCompanyList":
      return axios.get(`${URI}/users/group-register/companies`, {
        ...headerValue,
        ...{
          params: param.queryKey[1],
        },
      });

    // 공고에 등록할 고객사의 채용공고 기본 정보를 가져오는 API 요청
    case "getCompanyDetail":
      return axios.get(
        `${URI}/users/group-register/companies/${param.queryKey[1].companyIdx}`,
        {
          ...headerValue,
          ...{
            params: param.queryKey[1],
          },
        }
      );

    //
    // [HR담당자] 회원가입시 get userData API

    case "getUserInfo_signUp":
      return axios.get(`${URI}/signup/user`, {
        ...headerValue,
        ...{
          params: { ...param.queryKey[1] },
        },
      });

    // 모든 개인 레포트 결과지 다운로드

    case "download_personalReport":
      return axios.get(`${URI}/modify/${param.queryKey[1].groupIdx}/personal`, {
        ...headerValue,
        ...{
          params: { ...param.queryKey[1] },
        },
      });

    // 삭제 예정 그룹 채용 공고 리스트를 가져오는 API 요청

    case "removedGroupList":
      return axios.get(`${URI}/users/group-manage/groups/temp-deletion`, {
        ...headerValue,
        ...{
          params: param.queryKey[1],
        },
      });
    case "testSetStatus":
      return axios.get(
        `${URI}/user/groupManage/analytic/${param.queryKey[1].groupIdx}`,
        {
          ...headerValue,
        }
      );
    case "aiLog":
      return axios.get(`${URI}/aiLog/${param.queryKey[1].testerIdx}`, {
        ...headerValue,
        ...{
          params: { perPage: param.queryKey[1].perPage },
        },
      });
    // AI 로그 현황을 공고 기준으로 엑셀 다운로드하는 API
    case "downloadAiData":
      return axios.get(
        `${URI}/aiLog/analytic/${param.queryKey[1].groupIdx}/xlsx`,
        {
          ...headerValue,
        }
      );

    // 공고의 중복 응시자를 검색하는 API

    case "getDuplicateTesters":
      return axios.get(`${URI}/users/group-manage/testers/duplicates`, {
        ...headerValue,
        ...{
          params: { pageNo: param.queryKey[1].pageNo },
        },
      });

    // [HR담당자] 검사 스코링 API
    case "scoreTest":
      return axios.get(
        `${URI}/user/scoreTest/${param.queryKey[1].groupIdx}/${param.queryKey[1].progressNo}`,
        {
          ...headerValue,
          ...{
            params: {
              progressNo: param.queryKey[1].progressNo,
              groupIdx: param.queryKey[1].groupIdx,
            },
          },
        }
      );

    default:
      break;
  }
};

export const hr_uris_POST = async (flag, params, pilotFlag) => {
  const company = window.location.pathname.split("/")[2];
  let auth = sessionStorage.getItem(company);
  if (auth === null) auth = await localStorage.getItem(company);
  const URI = uriDivert(pilotFlag);
  const headerValue = {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  };
  switch (flag) {
    // 그룹채용 공고를 등록하는 API 요청

    case "createGroup":
      return axios.post(`${URI}/users/group-register`, params, headerValue);

    //화상룸의 담당 매니저에게 알림톡을 개별 발송하는 API 요청
    case "noticeManager":
      return axios.post(
        `${URI}/user/groupManage/roomList/noticeManager`,
        { roomIdxs: params.roomIdx, noticeMessage: params.noticeMessage },
        { params: { noticeType: params.noticeType }, ...headerValue }
      );
    // 공고의 화상룸 매니저들에게 알림톡/문자를 일괄적으로 발송하는 API 요청
    case "noticeManagerAll":
      return axios.post(
        `${URI}/user/groupManage/${params.groupIdx}/noticeManager`,
        { noticeMessage: params.noticeMessage },
        {
          params: { noticeType: params.noticeType },
          ...headerValue,
        }
      );

    // 공고의 화상룸의 응시자들에게 전화 요청을 일괄적으로 보내는 API 요청
    case "callAll":
      return axios.post(
        `${URI}/user/groupManage/roomList/${params.roomIdx}/call`,
        null,
        {
          params: { roomIdx: params.roomIdx },
          ...headerValue,
        }
      );

    // 그룹채용 공고를 수정하는 API 요청
    case "updateGroup":
      return axios.put(
        `${URI}/user/groupRegister/${params.groupIdx}`,
        { ...params.submitValues },
        headerValue
      );

    // 공고의 화상룸 감독관 리스트를 업로드하는 API
    case "uploadManager":
      return axios.post(
        `${URI}/upload/user/groupManage/${params.groupIdx}/managerUpload`,
        { managerListXlsx: params.managerListXlsx },
        {
          ...{
            headers: {
              "Content-Type": "multipart/form-data",
              ...headerValue.headers,
            },
            params: {
              groupIdx: params.groupIdx,
            },
          },
        }
      );

    // 공고의 화상룸 감독관 임시폴더를 삭제하는 API
    case "deleteUploadFile":
      return axios.delete(
        `${URI}/upload/user/groupManage/${params.groupIdx}/managerList`,
        {
          data: { groupIdx: params.groupIdx },
          ...headerValue,
        }
      );

    // 공고의 화상룸 감독관 리스트를 반영하는 API
    case "createManagerUpload":
      return axios.post(
        `${URI}/upload/user/groupManage/${params.groupIdx}/managerList`,
        null,
        {
          params: { groupIdx: params.groupIdx },
          ...headerValue,
        }
      );

    // [HR담당자] 응시자 리스트 등록 API
    case "uploadTester":
      const formData = new FormData();
      formData.append("testerList", params.testerList);
      return axios.post(
        `${URI}/users/tester-register/groups/${params.groupIdx}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headerValue.headers,
          },
        }
      );

    // [HR담당자] 응시자 리스트 등록 진행 상태 조회 API

    case "uploadTester2":
      return axios.post(
        `${URI}/user/testerRegister/${params.groupIdx}/progress/${params.progressNo}`,
        null,
        {
          params: { groupIdx: params.groupIdx, progressNo: params.progressNo },
          headers: {
            ...headerValue.headers,
          },
        }
      );

    // 공고의 응시자 상세 정보를 수정하는 API 요청
    case "updateRoomTester":
      return axios.put(
        `${URI}/users/group-manage/testers/${params.index}`,
        { ...params.values },
        headerValue
      );

    // [HR담당자] HR담당자 정보 수정 API
    case "updateManager":
      return axios.patch(
        `${URI}/user/groupManage/testerList/${params.index}`,
        { ...params.values },
        headerValue
      );

    // [HR담당자] HR담당자 삭제 API
    case "deleteManager":
      return axios.delete(`${URI}/user/delete/${params.userIdx}`, {
        data: { eventReason: params.eventReason },
        ...{ ...headerValue, ...params.userIdx },
      });

    // 공고의 응시자 검사 상태 정보를 수정하는 API 요청
    case "updateTesterStatus":
      return axios.patch(
        `${URI}/user/groupManage/testerList/examInfo/${params.testerIdx}`,
        params.data,
        headerValue
      );

    // 공고의 응시자 응시 포기 상태를 수정하는 API 요청 [HR담당자] HR담당자 권한 수정 API
    case "updateTesterGiveUp":
      return axios.patch(
        `${URI}/user/groupManage/testerList/${params.testerIdx}`,
        params.data,
        headerValue
      );

    case "updateUserGrade":
      return axios.patch(`${URI}/user/role/${params.userIdx}`, {}, headerValue);

    // [HR담당자] 공고의 응시자 일괄 방 배정 API

    case "assignRoom":
      return axios.post(
        `${URI}/users/tester-register/room-assignment/groups/${params.groupIdx}`,
        {},
        headerValue
      );

    // [감독관] 감독관 백도어 로그인 API

    case "moveToSupervisor":
      return axios.post(
        `${URI}/users/login/backdoor/managers/rooms/${params.roomIdx}`,
        null,
        headerValue
      );

    // 특정 화상룸으로 응시자들을 이동시키는 API
    case "moveRoom":
      return axios.patch(
        `${URI}/user/groupManage/moveRoom/${params.roomIdx}`,
        null,
        {
          ...headerValue,
          params: { testerIdxs: params.testerIdxs },
        }
      );

    // [HR담당자] 공고의 응시자 방 정보 생성 API
    case "addOneRoom":
      return axios.post(
        `${URI}/users/tester-register/rooms/groups/${params.groupIdx}`,
        null,
        headerValue
      );

    // [HR담당자] 공고에 응시자 개별 등록 API
    case "addOneTester":
      return axios.post(
        `${URI}/users/tester-register/groups/${params.groupIdx}/one`,
        { ...params.addOneTester },
        headerValue
      );

    // [HR담당자] 공고에 응시자 개별 삭제 API
    case "deleteTesters":
      return axios.delete(
        `${URI}/users/tester-register/groups/${params.groupIdx}`,
        {
          data: { testerIdxList: params.testerIdx },
          ...{ ...headerValue, ...params.userIdx },
        }
      );

    // [HR담당자] 공고의 방 정보 삭제 API
    case "deleteSelectRoom":
      return axios.delete(
        `${URI}/users/tester-register/rooms/groups/${params.groupIdx}`,
        {
          data: { roomIdxList: params.roomIdxList },
          ...headerValue,
        }
      );

    // 그룹채용 공고를 삭제하는 API 요청 (논리 삭제)
    case "deleteGroup":
      return axios.delete(
        `${URI}/users/group-manage/groups/${params.groupIdx}`,
        {
          data: { eventReason: params.eventReason },
          ...headerValue,
        }
      );

    // [HR담당자] 공고의 방 정보 수정 API
    case "createOneManager":
      return axios.put(
        `${URI}/users/tester-register/rooms/${params.roomIdx}`,
        { managerName: params.managerName, managerCell: params.managerCell },
        headerValue
      );

    // 화상감독을 사용하는 채용 공고의 응시자 출석 리스트 파일을 다운로드하는 API
    case "downloadTesterList":
      return axios.post(
        `${URI}/download/groupManage/${params.groupIdx}/testerList`,
        { eventReason: params.eventReason },
        headerValue
      );

    // [HR담당자] 가입 승인 API By HR
    case "approveHR":
      return axios.post(
        `${URI}/user/approve/hr/${params.userIdx}`,
        {},
        headerValue
      );
    // [HR담당자] 가입 거절 API By HR
    case "rejectHR":
      return axios.post(
        `${URI}/user/reject/hr/${params.userIdx}`,
        {},
        headerValue
      );

    // [HR담당자] 로그인을 하는 API (1차 인증)
    case "loginHR":
      return axios.post(`${URI}/users/login`, params, {
        ...headerValue,
      });

    //! companyCode는 링크주소 아니면 이메일로 들어옴

    // [HR담당자] 2차 인증 API
    case "login":
      return axios.post(
        `${URI}/users/login/2nd`,
        { userIdx: params.userIdx.userIdx, authKey: params.authKey },
        {
          ...headerValue,
          params: {
            companyCode: "ACG",
          },
        }
      );

    // [HR담당자] Sub 담당자 회원가입 API
    case "signUpHR_sub":
      return axios.post(
        `${URI}/signup/user`,
        { ...params.submit },
        {
          params: {
            companyCode: "ACG",
          },
        }
      );

    // 그룹채용 공고를 확정하는 API 요청

    case "confirmGroup":
      return axios.patch(
        `${URI}/users/group-register/groups/${params.groupIdx}`,
        null,
        {
          ...headerValue,
          params: {
            groupIdx: params.groupIdx,
            approveYN: params.approveYN,
          },
        }
      );

    // 모든 개인 레포트 탬플릿 생성
    case "personalHRreport":
      return axios.post(
        `${URI}/modify/${params.groupIdx}/personal/${params.progressNo}`,
        {},
        {
          ...headerValue,
          params: {
            groupIdx: params.groupIdx,
            progressNo: params.progressNo,
          },
        }
      );
    // 모든 면접 질문용 레포트 탬플릿 생성
    case "interviewerReport":
      return axios.post(
        `${URI}/modify/${params.groupIdx}/interview/${params.progressNo}`,
        {},
        {
          ...headerValue,
          params: {
            groupIdx: params.groupIdx,
            progressNo: params.progressNo,
          },
        }
      );

    // 공고 전체 응시자의 마킹값 파일을 다운로드하는 API
    case "downloadGroupMark":
      return axios.post(
        `${URI}/download/groupResult/${params.companyIdx}/${params.groupIdx}/markInfo`,
        { eventReason: params.eventReason },
        {
          ...headerValue,
          params: {
            companyIdx: params.companyIdx,
            groupIdx: params.groupIdx,
            markType: params.markType,
          },
        }
      );

    // 화상룸의 담당 감독관에게 발송하는 메시지 템플릿을 미리보기하는 API 요청

    case "previewMessage":
      return axios.post(
        `${URI}/user/groupManage/roomList/${params.roomIdx}/noticePreview`,
        { noticeMessage: params.noticeMessage },
        {
          ...headerValue,
          params: {
            roomIdx: params.roomIdx,
          },
        }
      );

    // 전체 HR 레포트 탬플릿 생성

    case "groupHRreport":
      return axios.post(`${URI}/modify/${params.groupIdx}/hr`, null, {
        ...headerValue,
        params: {
          groupIdx: params.groupIdx,
        },
      });

    // [HR담당자] HR담당자 로그인 상태에서 인증 번호 발송 API
    case "requestAuthKey":
      return axios.post(`${URI}/user/verify/${params.cell}/send`, null, {
        ...headerValue,
        params: {
          cell: params.cell,
        },
      });

    // [HR담당자] HR담당자 로그인 상태에서 인증 번호 발송 AND HR담당자 휴대폰 번호 수정 (By Self) API
    case "updateCellNum":
      return axios.patch(
        `${URI}/user/reset/cell`,
        { ...params },
        {
          ...headerValue,
        }
      );

    // [HR담당자] HR담당자 개인정보 수정 API
    case "updateUserBasicInfo":
      return axios.patch(`${URI}/user/info`, params.data, headerValue);

    // [HR담당자] HR담당자 비밀번호 수정 API
    case "updatePassword":
      return axios.patch(`${URI}/user/password`, params, headerValue);

    // [HR담당자] 비 로그인 상태에서 핸드폰 번호 받아서 인증번호 보내기 (비밀번호 초기화)
    case "requestPwAuth":
      return axios.post(
        `${URI}/user/check/cell`,
        { ...params },
        {
          ...headerValue,
        }
      );

    // [HR담당자] 비밀번호 초기화
    case "resetPassword":
      return axios.post(
        `${URI}/user/reset/password`,
        { ...params },
        {
          ...headerValue,
        }
      );

    // [HR담당자] 회원가입 인증번호 발송 API
    case "sendAuthKey_signUp":
      return axios.post(
        `${URI}/users/signup/send-code`,
        { ...params },
        {
          ...headerValue,
        }
      );

    // [HR담당자] 회원가입 인증번호 확인 API

    case "verifyAuthKey_signUp":
      return axios.post(
        `${URI}/users/signup/vertify-code`,
        { ...params },
        {
          ...headerValue,
        }
      );

    // [HR담당자] 회원가입 API
    case "signUpHR":
      return axios.post(`${URI}/users/signup`, params.data, {
        ...headerValue,
        params: {
          companyCode: params.companyCode,
          userIdx: params.userIdx,
        },
      });

    // [HR담당자] HR담당자 가입 안내 메일을 발송하는 API
    case "signUpEmailFromManager":
      return axios.post(`${URI}/user/invite`, params, {
        ...headerValue,
      });

    // 공고 내 특정 응시자들의 사전검사 정보를 수정하는 API (사전점검 미완료 -> 사전점검 완료)
    case "updatePreExamInfo":
      return axios.patch(
        `${URI}/user/groupManage/preExamInfo/testers`,
        params.selected,
        headerValue
      );

    // 채용 공고의 응시자 상태 리스트 파일을 다운로드하는 API
    case "testerStatusList":
      return axios.post(
        `${URI}/download/groupManage/${params.groupIdx}/testerStatusList`,
        { eventReason: params.eventReason },
        {
          ...headerValue,
        }
      );

    // 그룹채용 리스트를 엑셀로 내려받는 API 요청
    case "downloadGroupList":
      return axios.post(`${URI}/download/groupManage/groupList`, null, {
        ...headerValue,
        params: {
          ...params.conditions,
        },
      });

    case "deleteQnA":
      return axios.delete(`${URI}/user/qnaList/${params.qnaIdx}`, {
        data: { eventReason: params.eventReason },
        ...headerValue,
      });

    case "restoreQnA": {
      return axios.patch(
        `${URI}/user/qnaList/${params.qnaIdx}/tempDelete`,
        {},
        headerValue
      );
    }

    case "replyQnA":
      return axios.patch(
        `${URI}/user/qnaList/${params.qnaIdx}`,
        params.values,
        headerValue
      );

    // 삭제 예정 채용 공고를 복원하는 API 요청

    case "restoreGroup":
      return axios.patch(
        `${URI}/user/groupManage/groupList/${params.groupIdx}/tempDelete`,
        {},
        {
          ...headerValue,
          params: {
            groupIdx: params.groupIdx,
          },
        }
      );

    //! DEPRECATED
    // 그룹채용 테스트 공고를 일괄 삭제하는 API 요청
    case "deleteAllTestGroup":
      return axios.delete(`${URI}/user/groupManage/test/groupList`, {
        ...headerValue,
      });

    // 공고의 응시자 우선순위 규준을 수정하는 API

    case "assignTest":
      return axios.patch(
        `${URI}/user/groupManage/priority/${params.groupIdx}/${params.normIdx}`,
        {},
        {
          ...headerValue,
        }
      );

    // 기 응시자 리스트를 엑셀로 내려받는 API 요청

    case "downloadDuplicateTesters":
      return axios.post(`${URI}/download/groupManage/duplicate`, null, {
        ...headerValue,
      });

    // 개인/면접 레포트 탬플릿 적용 및 다운로드
    case "downloadReport":
      return axios.post(`${URI}/modify/${params.testerIdx}/individual`, null, {
        ...headerValue,
      });

    // [통계 담당자] 통계 담당자 가입 안내 메일을 발송하는 API
    case "signUpEmailFromStats":
      return axios.post(`${URI}/user/statistic/invite`, params, {
        ...headerValue,
      });

    // [사용자] 현재 로그인된 사용자 로그아웃하는 API
    case "logoutHR":
      return axios.post(`${URI}/logout/user`, params, {
        ...headerValue,
      });

    // 그룹/채용 공고 통계 리스트 파일을 다운로드하는 API
    case "downloadStatsList":
      return axios.post(`${URI}/download/statisticList/group`, null, {
        ...headerValue,
        params: {
          ...params,
        },
      });

    // 그룹 기간 내 전체 응시자 리스트 다운로드하는 API
    case "downloadTesterAll":
      return axios.post(
        `${URI}/download/statisticList/every`,
        {},
        {
          ...headerValue,
          params: {
            ...params,
          },
        }
      );

    // 고객사별 통계 리스트 파일을 다운로드하는 API
    case "downloadSummary":
      return axios.post(`${URI}/download/statisticList/company`, null, {
        ...headerValue,
        params: {
          ...params,
        },
      });
    // 특정 채용/공고의 응시자 리스트 파일을 다운로드하는 API
    case "downloadTesterListStats":
      return axios.post(
        `${URI}/download/statisticList/${params.groupIdx}/tester`,
        null,
        {
          ...headerValue,
          params: {
            testerInfo: params.testerInfo,
          },
        }
      );

    // 모든 특화 요인 레포트 탬플릿 생성
    case "customizedReport":
      return axios.post(
        `${URI}/modify/${params.groupIdx}/cumstomized/${params.progressNo}`,
        {},
        {
          ...headerValue,
          params: {
            groupIdx: params.groupIdx,
            progressNo: params.progressNo,
          },
        }
      );

    case "uploadMarkData":
      return axios.post(
        `${URI}/upload/user/groupManage/${params.groupIdx}/testerMarkList/personality`,
        { testerMarkList: params.testerMarkList },
        {
          ...{
            headers: {
              "Content-Type": "multipart/form-data",
              ...headerValue.headers,
            },
            params: {
              groupIdx: params.groupIdx,
            },
          },
        }
      );
    case "uploadMarkDataProgress":
      return axios.post(
        `${URI}/upload/user/groupManage/${params.groupIdx}/testermarkList/personality/progress`,
        { fileName: params.fileName },
        {
          ...{
            headers: {
              ...headerValue.headers,
            },
            params: {
              groupIdx: params.groupIdx,
            },
          },
        }
      );

    case "uploadMarkDataAptitude":
      return axios.post(
        `${URI}/upload/user/groupManage/${params.groupIdx}/testermarkList/aptitude`,
        { testerMarkList: params.testerMarkList },
        {
          ...{
            headers: {
              "Content-Type": "multipart/form-data",
              ...headerValue.headers,
            },
            params: {
              groupIdx: params.groupIdx,
            },
          },
        }
      );
    case "uploadMarkDataAptitudeProgress":
      return axios.post(
        `${URI}/upload/user/groupManage/${params.groupIdx}/testermarkList/aptitude/progress`,
        { fileName: params.fileName },
        {
          ...{
            headers: {
              ...headerValue.headers,
            },
            params: {
              groupIdx: params.groupIdx,
            },
          },
        }
      );

    default:
      break;
  }
};
