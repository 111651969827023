import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

// Style
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import { ReactComponent as Spinner } from "assets/icon/downloadSpinner-white.svg";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { ExternalLink, Search, Trash2, UserCheck } from "lucide-react";
import { tableHeader, tableWrapper } from "themes/theme";
// Components
import LoadingCircle from "components/UI/organism/LoadingCircle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import TopTitle from "components/templates/topTitle/TopTitle";
import MenuComponent from "components/UI/menu/MenuComponent";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
// Recoil
import { useRecoilValue } from "recoil";
import { companyInfoAtom, HRcompanyInfo } from "recoil/store";

// React-query
import { useQueryClient } from "@tanstack/react-query";
import useGetQueryhr from "hooks/useGetQueryhr";
import useMutationHook from "hooks/useMutationHook";

// Router
import { useLocation, useParams } from "react-router-dom";
// Dialog
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import Swal from "sweetalert2";
// Enum
import {
  MESSAGE_DELETE_TESTER,
  MESSAGE_MOVE_MANAGER_PAGE,
  MESSAGE_MOVE_TESTER,
  MESSAGE_UPDATE_TESTER_STATUS,
} from "enums/modalMessage";
import {
  GROUP_ROOM_TESTER_LIST,
  GROUP_TESTER_LIST_MORE_LIST,
} from "enums/tableColumn";

// Utils
import { aiLevel } from "utils/aiLevel";
import { bizCode } from "utils/bizCode";
import { checkboxSelect } from "utils/checkboxSelect";
import { roomTesterListTimeFormat } from "utils/formatter/dateFormat";
import { YNColor } from "utils/formatter/YNColor";
import { examStatusTag } from "utils/tagRender";
import { modalReducer } from "reducer/reducer";
//  Hooks
import useCopyClipboard from "hooks/useCopyClipboard";
import { supervisorURL } from "services/api/APIs";

const Idcardcheckmodal = lazy(() => import("./testerMenu/IdCardCheckModal"));
const UpdateRoomTesterModal = lazy(() =>
  import("./testerMenu/UpdateRoomTesterModal")
);
const UpdateTestStatusModal = lazy(() =>
  import("./testerMenu/UpdateTestStatusModal")
);
const TesterLogDataModal = lazy(() =>
  import("./testerMenu/TesterLogDataModal")
);
const TesterChatLogModal = lazy(() =>
  import("./testerMenu/TesterChatLogModal")
);
const TesterVideoLogModal = lazy(() =>
  import("./testerMenu/TesterVideoLogModal")
);
const AiCountModal = lazy(() => import("./testerMenu/AiCountModal"));
export default function RoomTesterList() {
  const { id, company } = useParams(); //*RoomIdx
  const testerInputRef = useRef(null);
  const [triggerMoverRoomList, setTriggerMoverRoomList] = useState(false);
  const [selected, setSelected] = useState([]);
  const location = useLocation();
  const [moreBtnAnchor, setMoreBtnAnchor] = useState(null);
  const handleTriggerGetRoomList = () => setTriggerMoverRoomList(true);
  const openMoreBtn = Boolean(moreBtnAnchor);
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  //* MODAL STATUS

  const [paramValues, setParamValues] = useState({
    page: { totalGroup: 0, pageNo: 1, totalPage: 0, perPage: 50 },
  });
  const { platform } = useRecoilValue(companyInfoAtom);
  const companyInfo = useRecoilValue(HRcompanyInfo);

  const [state, dispatch] = useReducer(modalReducer, { modal: null });

  //* 선택된 응시자 항목 정보
  const [roomTesterInfo, setRoomTesterInfo] = useState({});
  const [selectRoomIdx, setSelectRoomIdx] = useState("");

  const [showTester, setShowTester] = useState(null);
  testerInputRef.current?.focus();
  //* 룸 응시자 목록 조회
  const { data, pageInfo, isLoading, isFetching } = useGetQueryhr(
    "roomTesterList",
    {
      ...paramValues,
      ...{ index: Number(id) },
    },
    true,
    false
  );

  //* 감독관 페이지 이동
  const { mutate: moveToSupervisor } = useMutationHook(
    "moveToSupervisor",
    "HR"
  );
  //* 룸 이동시키기
  const { mutate: moveRoomMutate } = useMutationHook("moveRoom", "HR");

  //* 응시자 삭재
  const { mutate: deleteTester } = useMutationHook("deleteTesters", "HR");

  //* 응시자 사전점검 상태 변경
  const { mutate: updatePreExamInfo } = useMutationHook(
    "updatePreExamInfo",
    "HR"
  );

  //* 개인 Report 다운로드
  const {
    mutate: downloadReport_personal,
    isLoading: downloadReport_personal_loading,
  } = useMutationHook("downloadReport", "HR");

  //* 이동가능한 룸 목록 조회
  const { data: moveRoomList } = useGetQueryhr(
    "moveRoomList",
    {
      ...paramValues,
      ...{
        groupIdx: location.state?.groupIdx,
        testerIdxs: selected.toString(),
        roomIdx: Number(id),
      },
    },
    triggerMoverRoomList,
    false
  );
  //* 행 클립보드 복사
  const {
    copyResultMessage,
    copyResult,
    handleCopyClipBoard,
    handleCloseAlert,
    isCopyError,
  } = useCopyClipboard("roomTesterList");

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    setSelected([]);
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  const handleOpenModal = (modal) => {
    dispatch({
      type: !state.modal ? modal : "close-modal",
      payload: { modal },
    });
  };
  const handleCloseModal = () => {
    dispatch({ type: "close-modal" });
  };
  const handleCloseMoreBtn = () => {
    setMoreBtnAnchor(null);
  };
  //* CheckBox
  const handleCheckClick = (_, name) => {
    const { testerIdx } = name;
    const selectedIndex = selected.indexOf(testerIdx);
    setSelected(checkboxSelect(selected, selectedIndex, testerIdx));
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.testerIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectMenu = (e, row) => {
    const { value } = e.target;
    setRoomTesterInfo(row);
    if (value === 1) handleOpenModal("updateTester");
    if (value === 2) handleOpenModal("testStatus");
    if (value === 3) handleOpenModal("idCardCheckModal"); //신분증
    if (value === 4) handleOpenModal("logData");
    if (value === 5) handleOpenModal("videoLog"); // 녹화영상
    if (value === 6) handleOpenModal("chatLog");
    if (value === 7) handleDownloadReport(row);
    setMoreBtnAnchor(null);
  };

  const handleDownloadReport = (row) => {
    Swal.fire(
      confirmModalConfig({
        text: "해당 응시자의 Report를 다운로드 하시겠습니까?",
        title: "개인 Report 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        downloadReport_personal(
          {
            testerIdx: row.testerIdx,
          },
          {
            onSuccess: (res) => {
              handleClose();
              window.open(`https://${res.data.data}`);
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  title: "개인 Report 다운로드",
                  text: error.message,
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              ).then((res) => {
                if (res.isConfirmed) {
                  handleClose();
                }
              });
            },
          }
        );
      }
    });
  };

  const handleSelectRoom = (e, row) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_MOVE_TESTER,
        title: "화상룸 이동",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        moveRoomMutate(
          {
            roomIdx: e.target.value,
            testerIdxs: selected.toString(),
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "룸 이동이 완료되었습니다.",
                  title: "화상룸 이동",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) {
                  setSelected([]);
                  queryClient.invalidateQueries({
                    queryKey: ["roomTesterList"],
                  });
                }
              });
            },
          }
        );
      }
    });
  };
  const handleMoveToAdvisor = () =>
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_MOVE_MANAGER_PAGE,
        title: "감독관 페이지 이동",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        const url = supervisorURL();
        moveToSupervisor(
          { roomIdx: Number(id) },
          {
            onSuccess: (res) => {
              window.open(
                `${url}/${platform}/Login/?loginRoomIDX=${id}&loginAuthKey=${res.data.data.authKey}`,
                "_blank"
              );
            },
          }
        );
      }
    });

  const handleDeleteTester = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DELETE_TESTER,
        title: "응시자 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        deleteTester(
          {
            testerIdx: selected,
            groupIdx: location.state?.groupIdx,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "응시자 삭제가 완료되었습니다.",
                  title: "응시자 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomTesterList");
              });
            },
          }
        );
      }
    });
  };

  const handleUpdatePreExamInfo = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_TESTER_STATUS,
        title: "사전점검 확인",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        updatePreExamInfo(
          {
            selected,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "해당 응시자 사전점검 상태가 '완료'로 변경되었습니다.",
                  title: "사전점검 확인",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomTesterList");
              });
            },
          }
        );
      }
    });
  };

  useEffect(() => {
    selected.length === 0 && setSelectRoomIdx("");
  }, [selected]);

  useEffect(() => {
    if (location.state) {
      setParamValues((prev) => ({
        ...prev,
        pageNo: location.state.pageNo,
        //* 응시자 검색으로 들어올 시 total정보가 없으므로 50으로 고정(변동가능)
        perPage: 50,
      }));

      setShowTester(location.state.testerIdx);
    }
  }, [location]);

  useEffect(() => {
    if (downloadReport_personal_loading) {
      handleOpen();
    }
  }, [downloadReport_personal_loading]);

  const TABLE_ROW = useMemo(() => {
    let temp = [...GROUP_ROOM_TESTER_LIST];
    if (companyInfo.companyIdx === 1) {
      return temp;
    } else {
      return temp.filter((item) => item.label !== "AI 감지");
    }
  }, []);

  const MENU_LIST = useMemo(() => {
    let temp = [...GROUP_TESTER_LIST_MORE_LIST];
    if (companyInfo.companyIdx === 1) {
      return temp;
    } else {
      return temp.filter(
        (item) => item.index === 1 || item.index === 3 || item.index === 4
      );
    }
  }, []);

  const handleCheckAIcount = (_, row) => {
    setRoomTesterInfo(row);
    handleOpenModal("AI");
  };

  const handleSearchTester = () => {
    setParamValues((prev) => ({
      ...prev,
      pageNo: 1,
      perPage: 50,
      searchInfo: { testerKeyword: testerInputRef.current.value },
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchTester();
    }
  };

  const handleClickMenu = (event, row) => {
    setMoreBtnAnchor(event.currentTarget);
    setRoomTesterInfo(row);
  };
  return (
    <Box
      width="100%"
      height="100%"
      borderRadius={2}
      display="flex"
      flexDirection="column"
      rowGap={2}
    >
      <Grid container justifyContent={"space-between"} mb={1}>
        <TopTitle
          list={[
            {
              title: "채용그룹 관리",
              url: `/acghr_hr/${company}/group`,
              current: false,
            },
            {
              title: "화상룸 목록",
              url: `/acghr_hr/${company}/roomList${location.state.groupIdx}`,
              current: false,
            },
            {
              title: `응시자리스트 / ${
                data?.data[0]?.roomNo || location.state?.roomNo || 0
              }번 룸`,
              url: `/acghr_hr/${company}/roomList/testerList${id}`,
              current: true,
            },
          ]}
        />
      </Grid>

      {isFetching || isLoading ? (
        <LoadingSkeleton />
      ) : data && data.data.length < 1 ? (
        <Paper
          sx={{
            ...tableWrapper,
            overflowY: "hidden",
            height: "100%",
          }}
          variant="outlined"
        >
          <Grid
            container
            justifyContent={"center"}
            alignItems="center"
            height={"100%"}
          >
            <EmptyPlaceholder />
          </Grid>
        </Paper>
      ) : (
        <>
          <Paper
            sx={{
              ...tableWrapper,
              overflowY: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
              p: 2,
              rowGap: 2,
            }}
            variant="outlined"
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Grid container alignItems="center" columnGap={2}>
                  <OutlinedInput
                    size="small"
                    inputRef={testerInputRef}
                    placeholder={`성명 또는 휴대전화 번호를 입력해 주세요.`}
                    onKeyUp={(e) => handleKeyPress(e, "searchEnter")}
                    sx={{ width: 300 }}
                  />
                  <Button
                    variant="contained"
                    disableElevation
                    startIcon={<Search size={15} strokeWidth={1.3} />}
                    onClick={handleSearchTester}
                  >
                    검색
                  </Button>

                  <Typography
                    fontWeight={700}
                  >{`${pageInfo.total}명`}</Typography>
                </Grid>
              </Box>
              <Box>
                <Stack direction={"row"} columnGap={1}>
                  {companyInfo.companyIdx === 1 ? (
                    location.state?.roomNo !== 0 ? null : (
                      <Button
                        disabled={selected.length === 0 ? true : false}
                        variant="outlined"
                        onClick={handleUpdatePreExamInfo}
                        startIcon={<UserCheck size={15} />}
                      >
                        사전점검 확인
                      </Button>
                    )
                  ) : null}

                  {companyInfo.companyIdx === 1 && (
                    <TextField
                      select
                      sx={{
                        background: "white",
                        borderRadius: "7px",
                        minWidth: 130,
                        overflow: "auto",
                      }}
                      size="small"
                      label="룸 선택 및 이동"
                      disabled={selected.length === 0 ? true : false}
                      onFocus={handleTriggerGetRoomList}
                      onChange={handleSelectRoom}
                      value={selectRoomIdx}
                    >
                      {moveRoomList ? (
                        moveRoomList.map((item, index) => (
                          <MenuItem key={item.roomIdx} value={item.roomIdx}>
                            {`${item.roomNo}번 룸`}
                          </MenuItem>
                        ))
                      ) : (
                        <Box />
                      )}
                    </TextField>
                  )}

                  {companyInfo.companyIdx === 1 && (
                    <>
                      {location.state?.roomNo !== 0 ? (
                        <Button
                          startIcon={<ExternalLink size={15} />}
                          variant="outlined"
                          onClick={handleMoveToAdvisor}
                        >
                          감독관 페이지 이동
                        </Button>
                      ) : null}
                      <Button
                        startIcon={<Trash2 size={15} />}
                        variant="contained"
                        color="removeButton"
                        disableElevation
                        sx={{ "&": { color: "#c42c08" } }}
                        onClick={handleDeleteTester}
                        disabled={selected.length <= 0 ? true : false}
                      >
                        응시자 삭제
                      </Button>
                    </>
                  )}
                </Stack>
              </Box>
            </Stack>
            <TableContainer sx={{ height: "calc(95% - 20px)" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" align="center" sx={{ width: 30 }}>
                      <Checkbox
                        size="small"
                        style={{ color: "#637381" }}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    {TABLE_ROW.map((item) => (
                      <TableCell
                        key={item.label}
                        sx={{ width: item.size, fontWeight: "bold" }}
                        align="center"
                        padding="none"
                      >
                        {item.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.data.map((row, index) => (
                      <TableRow
                        hover
                        key={row.testerIdx}
                        sx={{
                          "&": { height: 43 },
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        tabIndex={-1}
                        className={
                          row.testerIdx === showTester ? "roomTesterFocus" : ""
                        }
                      >
                        <TableCell align="center" padding="none">
                          <Checkbox
                            size="small"
                            color="primary"
                            onClick={(event) => handleCheckClick(event, row)}
                            checked={isSelected(row.testerIdx)}
                          />
                        </TableCell>
                        <TableCell align="center" padding="none">
                          <Button
                            onClick={() => handleCopyClipBoard(row)}
                            size="small"
                            variant="outlined"
                            sx={{ "&": { maxWidth: 40, minWidth: 40 } }}
                          >
                            복사
                          </Button>
                        </TableCell>
                        <TableCell padding="none" align="center">
                          <Typography noWrap variant="body2">
                            {bizCode(row.bizCode)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {row.testerName}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.testerBirth}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.testerCell}
                        </TableCell>

                        <TableCell padding="none" align="center">
                          {YNColor(row.preExamYN)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {YNColor(row.foreignLoginYN)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {YNColor(row.attendanceYN)}
                        </TableCell>
                        {companyInfo.companyIdx === 1 ? (
                          <TableCell
                            padding="none"
                            align="center"
                            onClick={(e) => handleCheckAIcount(e, row)}
                          >
                            <Box
                              width="65%"
                              mx="auto"
                              position="relative"
                              top={0}
                              sx={{
                                cursor: "pointer",
                                transition: "all ease 0.5s",
                                "&:hover": {
                                  filter: "brightness(0.93)",
                                  transition: "all ease 0.5s",
                                },
                              }}
                            >
                              {aiLevel(row.aiLogCnt)}
                            </Box>
                          </TableCell>
                        ) : null}

                        <TableCell padding="none" align="center">
                          {roomTesterListTimeFormat(row.logAt)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          <Typography noWrap variant="body2">
                            {row.normName}
                          </Typography>
                        </TableCell>

                        <TableCell padding="none" align="center">
                          <Stack
                            direction={"row"}
                            columnGap={1}
                            justifyContent="center"
                          >
                            <Box position={"relative"}>
                              {examStatusTag(row.examStatus)}
                              {row.giveUpYN === "Y" && (
                                <Box
                                  position={"absolute"}
                                  top={2.5}
                                  right={-20}
                                >
                                  <Tooltip
                                    title="응시포기"
                                    arrow
                                    placement="top"
                                  >
                                    <ErrorOutlineRoundedIcon color="error" />
                                  </Tooltip>
                                </Box>
                              )}
                            </Box>
                          </Stack>
                        </TableCell>

                        <TableCell padding="none" align="center">
                          <Button onClick={(e) => handleClickMenu(e, row)}>
                            <MoreHorizIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Menu
              anchorEl={moreBtnAnchor}
              open={openMoreBtn}
              onClose={handleCloseMoreBtn}
            >
              {MENU_LIST.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={(e) => handleSelectMenu(e, roomTesterInfo)}
                    value={item.index}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Menu>
            <Grid container alignItems={"center"} justifyContent="center">
              <Pagination
                size="small"
                count={pageInfo.totalPage}
                page={pageInfo.pageNo}
                onChange={(_, page) => {
                  setParamValues((prev) => ({
                    ...prev,
                    pageNo: page,
                  }));
                }}
              />
            </Grid>
          </Paper>
        </>
      )}
      {/* 응시자 정보수정 */}
      <Dialog
        open={state.modal === "updateTester"}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateRoomTesterModal
            roomTesterInfo={roomTesterInfo}
            onClose={handleCloseModal}
          />
        </Suspense>
      </Dialog>
      {/* 응시자 진행상태 정보 수정 */}
      <Dialog
        open={state.modal === "testStatus"}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateTestStatusModal
            roomTesterInfo={roomTesterInfo}
            onClose={handleCloseModal}
          />
        </Suspense>
      </Dialog>
      {/* 응시자 접속로그 데이터 모달 */}
      <Dialog
        open={state.modal === "logData"}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterLogDataModal
            roomTesterInfo={roomTesterInfo}
            onClose={handleCloseModal}
          />
        </Suspense>
      </Dialog>
      {/* 신분증 확인 모달 */}
      <Dialog
        open={state.modal === "idCardCheckModal"}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <Suspense fallback={<LoadingCircle />}>
          <Idcardcheckmodal
            roomTesterInfo={roomTesterInfo}
            onClose={handleCloseModal}
          />
        </Suspense>
      </Dialog>
      {/* 채팅로그 모달 */}
      <Dialog
        open={state.modal === "chatLog"}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterChatLogModal
            roomTesterInfo={roomTesterInfo}
            onClose={handleCloseModal}
          />
        </Suspense>
      </Dialog>
      {/* 비디오로그 모달 */}
      <Dialog
        open={state.modal === "videoLog"}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="lg"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterVideoLogModal
            roomTesterInfo={roomTesterInfo}
            onClose={handleCloseModal}
          />
        </Suspense>
      </Dialog>

      {/* AI 감지 모달 */}
      <Dialog
        open={state.modal === "AI"}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <AiCountModal
            roomTesterInfo={roomTesterInfo}
            onClose={handleCloseModal}
          />
        </Suspense>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={copyResult}
        autoHideDuration={1000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={isCopyError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          <Typography fontWeight={700}>{copyResultMessage}</Typography>
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Stack direction="column" rowGap={1} alignItems="center">
          <Spinner />
          <Typography
            component="div"
            color="white"
            fontWeight={700}
            sx={{
              clipPath: "inset(0 2.5ch 0 0)",
              animation: "1s steps(4) infinite",
              fontSize: "15px",
              animationName: "l",
              "@keyframes l": {
                to: {
                  clipPath: "inset(0 -1ch 0 0)",
                },
              },
            }}
          >
            레포트 정보를 생성중입니다. . .
          </Typography>
        </Stack>
      </Backdrop>
    </Box>
  );
}
